#add-edit-role-form {
  .portal-name-color {
    color: $form-check-input-checked-bg-color !important;
  }

  input[type="checkbox"]:checked {
    background-color: $form-check-input-checked-bg-color;
  }

  .form-label {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
  }

  .permission-table {
    max-height: calc(100svh - 450px);

    thead {
      position: sticky;
      top: 0;
    }
  }
}
