﻿@mixin icon($name, $filename) {
  .#{$name} {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    width: 1em;
    height: 1em;
    mask-image: url("../../../image/Icons/#{$filename}");
    -webkit-mask-image: url("../../../image/Icons/#{$filename}");
    mask-size: cover;
    mask-repeat: no-repeat;
    background-color: currentColor;
    color: inherit;
    vertical-align: middle;
  }
}

@mixin icon-without-mask($name, $filename) {
  .#{$name} {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    width: 1em;
    height: 1em;
    background-image: url("../../../image/Icons/#{$filename}");
    -webkit-image: url("../../../image/Icons/#{$filename}");
    background-size: cover;
    background-repeat: no-repeat;
    color: inherit;
    vertical-align: middle;
  }
}
