@import "../../../assets/style/theme/mixins/DirectionsMixins.Style.scss";

.date-picker-wrapper {
  span.icon-date-picker {
    position: absolute;
    top: 36px;

    right: 16px;
    @include rtl {
      right: unset;
      left: 16px;
    }
  }
}

.date-picker-wrapper {
  span.icon-date-picker-without-label {
    position: absolute;
    top: 12px;

    right: 16px;
    @include rtl {
      right: unset;
      left: 16px;
    }
  }
}
