@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$font-size-base: 0.875rem;
$dark: #010a10;
$primary: #3992a4;
$gray: #a09f9f;
$border-color: #e5e5e5;
$darkGray: #505050;
$form-check-input-checked-bg-color: #ab986c;
$danger: #f74d31;
$border-color: #e5e5e5;
$bs-link-color: #3992a4;
$bs-link-hover-color: #2a6a78;
$body-bg: #f3f6f9;
$body-color: #a09f9f;
$active-color: $white;
$header-height: 96px;
.btn-outline-primary {
  --bs-btn-active-color: #fff;
}
.btn-primary {
  --bs-btn-color: #fff;
}
.btn {
  --bs-btn-font-size: 0.875rem;
  --bs-btn-padding-y: 0.45rem;
  --bs-btn-padding-x: 0.425rem;
}

.btn-link {
  --bs-link-color: #3992a4;
  --bs-link-hover-color: #2a6a78;
}
.dropdown-menu {
  --bs-dropdown-font-size: 0.875rem;
}
//.btn-link {
//  color: $primary;
//  &:hover {
//    color: $bs-link-hover-color;
//  }
//}

.popover {
  --bs-popover-arrow-height: 0.8rem;
  --bs-popover-arrow-width: 1.5rem;
  --bs-popover-bg: #ffffff;
  --bs-popover-border-color: #ffffff;
}

$custom-colors: (
  "primary": $primary,
  "dark": $dark,
  "gray": $gray,
  "danger": $danger,
  "darkGray": $darkGray,
  "border-color": $border-color,
  "form-check-input-checked-bg-color": $form-check-input-checked-bg-color,
  "body-bg": $body-bg,
  "body-color": $body-color,
  "active-color": $active-color,
);
$theme-colors: map-merge($theme-colors, $custom-colors);
