.user-type-guide-button {
  background-color: var(--bs-primary);
  color: white;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.user-type-guide-modal-title {
  background-color: var(--bs-gray-100);
  padding: 16px;
  margin-bottom: 16px;
}
