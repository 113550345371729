﻿@import "./mixins/IconMixins.Style";
@include icon("icon-add-refree", "Property 1=add refree.svg");
@include icon("icon-add-time", "Property 1=Add Time.svg");
@include icon("icon-apologize", "Property 1=apologize.svg");
@include icon("icon-arrow-down", "Property 1=Arrow Down.svg");
@include icon("icon-arrow-left-small", "Property 1=Arrow Left small.svg");
@include icon("icon-arrow-left", "Property 1=Arrow Left.svg");
@include icon("icon-calendar", "Property 1=Calendar.svg");
@include icon("icon-center-management", "Property 1=Center management.svg");
@include icon("icon-close-big", "Property 1=Close big.svg");
@include icon("icon-close", "Property 1=Close.svg");
@include icon("icon-continue", "Property 1=continue.svg");
@include icon("icon-dashboard", "Property 1=Dashboard.svg");
@include icon("icon-delete", "Property 1=Delete.svg");
@include icon-without-mask("icon-doc-big", "Property 1=Doc big.svg");
@include icon("icon-doc", "Property 1=Doc.svg");
@include icon("icon-document", "Property 1=Docuemnt.svg");
@include icon("icon-double-arrow-left", "Property 1=Double Arrow Left.svg");
@include icon("icon-edit", "Property 1=Edit.svg");
@include icon("icon-email-templates", "Property 1=email Templates.svg");
@include icon("icon-export", "Property 1=Export.svg");
@include icon("icon-drag", "Drag.svg");
@include icon("icon-file", "Property 1=File.svg");
@include icon("icon-filter", "Property 1=Filter.svg");
@include icon("icon-form-builder", "Property 1=Form Builder.svg");
@include icon("icon-full-arrow-left", "Property 1=Full Arrow Left.svg");
@include icon("icon-full-arrow-right", "Property 1=Full Arrow Right.svg");
@include icon("icon-lockups", "Property 1=Lockups.svg");
@include icon("icon-logo-2", "Property 1=Logo 2.svg");
@include icon("icon-logo-big", "Property 1=Logo Big.svg");
@include icon("icon-logo-small", "Property 1=Logo Small.svg");
@include icon("icon-msgs", "Property 1=Msgs.svg");
@include icon("icon-news-management", "Property 1=News Management.svg");
@include icon("icon-notification", "Property 1=Notification.svg");
@include icon("icon-orders", "Property 1=Orders.svg");
@include icon-without-mask("icon-pdf-big", "Property 1=PDF big.svg");
@include icon("icon-pdf-templates", "Property 1=PDF Templates.svg");
@include icon("icon-pdf", "Property 1=PDF.svg");
@include icon("icon-percentage", "Property 1=Percentage.svg");
@include icon("icon-pin", "Property 1=Pin.svg");
@include icon("icon-plus", "Property 1=Plus.svg");
@include icon("icon-programs", "Property 1=Programs.svg");
@include icon("icon-reassign", "Property 1=Reassign.svg");
@include icon("icon-reject-order", "Property 1=reject order.svg");
@include icon("icon-search-24", "Property 1=Search 24.svg");
@include icon("icon-search", "Property 1=Search.svg");
@include icon("icon-send-contract", "Property 1=Send contract.svg");
@include icon("icon-send-password", "Property 1=Send Password.svg");
@include icon("icon-setting", "Property 1=Setting.svg");
@include icon("icon-settings", "Property 1=Settings.svg");
@include icon("icon-share", "Property 1=Share.svg");
@include icon("icon-speeches", "Property 1=Speeches.svg");
@include icon("icon-stop", "Property 1=Stop.svg");
@include icon("icon-submissions", "Property 1=Submissions.svg");
@include icon("icon-tasks", "Property 1=Tasks.svg");
@include icon("icon-terms-and-conditions", "Property 1=Terms and Conditions.svg");
@include icon("icon-tick-square", "Property 1=Tick Square.svg");
@include icon("icon-turn-order", "Property 1=Turn order.svg");
@include icon("icon-user-management", "Property 1=User Management.svg");
@include icon("icon-view", "Property 1=View.svg");
@include icon("icon-workflow", "Property 1=Workflow.svg");
@include icon("icon-yes", "Property 1=Yes.svg");
@include icon("icon-check", "CheckMark.svg");
@include icon("icon-arrow-right", "arrow-right.svg");
@include icon("icon-email-Templates", "Property 1=email Templates.svg");
@include icon("icon-bell", "bell.svg");
@include icon("icon-messages", "messages.svg");
@include icon("icon-arrow-left-with-tile", "arrow-left-with-tile.svg");
@include icon("icon-warning-circle", "warning-circle.svg");
@include icon("icon-x", "x-close.svg");
@include icon("icon-user-profile", "user-profile.svg");
@include icon("icon-clock", "clock.svg");
@include icon("icon-help", "help.svg");
@include icon("icon-change-lang", "change-lang.svg");
@include icon("icon-signout", "signout.svg");
@include icon("icon-researchers", "Property 1=Researchers.svg");
@include icon("icon-block", "Property 1=Block.svg");
@include icon("icon-eval-form", "evaluation-form.svg");
@include icon("icon-return", "return.svg");
@include icon("icon-warning", "warning.svg");
@include icon("icon-reward-accepted", "Property 1=Reward Accepted.svg");
@include icon("icon-reward", "Property 1=Reward.svg");
@include icon("icon-receipt", "receipt.svg");
@include icon("icon-burger", "burger-list.svg");
@include icon("icon-chevron-up", "Arrowup.svg");
@include icon("icon-chevron-down", "Arrowdown.svg");
@include icon("icon-rotate", "rotate.svg");
@include icon-without-mask("icon-epub", "epub.svg");

i {
  font-size: 1.4rem;
}
