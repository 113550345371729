// TODO: @Alaa, mixins are not loaded globally
@import "../../../assets/style/theme/mixins/DirectionsMixins.Style.scss";

.accordion.strip-styles {
  .accordion-item {
    background-color: white !important;
    color: black;
    border: none;
    .accordion-header {
      .accordion-button {
        box-shadow: none;
        color: black !important;
        background-color: white !important;

        &:not(.collapsed)::after {
          transform: rotate(0deg);
          @include rtl {
            transform: rotate(0deg);
            margin-left: unset;
            margin-right: 1.5rem;
          }
        }

        &::after {
          transform: rotate(-90deg);
          margin-left: 1.5rem;
          @include rtl {
            transform: rotate(90deg);
            margin-left: unset;
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
}
