﻿.pagination-container {
  gap: 10px;
}
.page-item {
  display: flex;
  align-items: center;
}
.page-link {
  padding: 5px 10px;
  margin: 0 2px;
  text-decoration: none;
  display: flex;
  vertical-align: center;

  color: $darkGray;
  border: 1px solid $border-color;
  border-radius: 0.375rem;
}
.page-item.active .page-link {
  background-color: $dark;
  color: $white;
}
.page-item.disabled .page-link {
  color: $gray;
  pointer-events: none;
}
.page-item:first-child .page-link {
  @include rtl {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.page-item:last-child .page-link {
  @include rtl {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.page-link {
  cursor: pointer;
}
