@import "../../theme/variables";

@font-face {
  font-family: "DroidArabicKufi";
  src: url("../../../font/Droid.Arabic.Kufi.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "DroidArabicKufi", sans-serif;
  scrollbar-width: thin;
}

.rtl {
  direction: rtl;
}

.form-control {
  padding: 10px !important;
}

a {
  text-decoration: none;
  color: $darkGray;
}

.main-container {
  display: flex;
  height: 100%;
  position: relative;
}

aside {
  flex: 0 0 250px;
}

main {
  flex: 1;
  padding: 20px;
}

html {
  height: 100svh;
}

body {
  height: calc(100svh - $header-height);
  min-height: 600px;
}

.m-15p {
  margin: 15px;
}

.calc-h-100 {
  height: calc(100% - 15px);
  min-height: 600px;
}

.table-rounded {
  border-collapse: separate;
  border-spacing: 0;

  a {
    color: $primary;
    text-decoration: underline;
  }

  tbody {
    td {
      color: $darkGray;
      background-color: $white;
      @include rtl {
        border-left: 1px solid #e5e5e5;
      }
      border-right: 1px solid #e5e5e5;
    }

    td:last-child {
      @include rtl {
        border-left: 0 solid #e5e5e5;
      }
      border-right: 0 solid #e5e5e5;
    }

    td:first-child {
      @include rtl {
        border-right: 0 solid #e5e5e5;
      }
      border-left: 0 solid #e5e5e5;
    }
  }

  thead {
    th {
      background-color: #fafafb;
      border: 1px solid #e5e5e5;
      color: $dark;
    }

    th:first-child {
      @include rtl {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    th:last-child {
      @include rtl {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
      }
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }

  tfoot {
    td {
      background-color: #fafafb;
      border-color: #e5e5e5;
      border-top-width: 1px;
      border-style: solid;
      padding-inline: 0;
      color: $dark;
    }

    td:first-child {
      @include rtl {
        border-right-width: 1px;
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: unset;
        border-top-left-radius: unset;
      }
      border-bottom-left-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }

    td:last-child {
      @include rtl {
        border-left-width: 1px;
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
        border-bottom-right-radius: unset;
        border-top-right-radius: unset;
      }
      border-bottom-right-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
  }

  &.has-footer {
    tbody {
      tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

.p-10-32P {
  padding: 10px 30px;
}

.py-10 {
  padding-block: 10px;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input {
  @include rtl {
    padding-right: 35px !important; /* Add space for the icon */
    padding-left: unset;
  }
  padding-left: 35px !important; /* Add space for the icon */
}

.input-with-icon i {
  position: absolute;
  @include rtl {
    right: 10px;
    left: unset;
  }
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.form-control {
  font-size: 0.875rem;
  color: $dark;
}

.form-control:focus {
  color: $dark;
}

.fs-32P {
  font-size: 32px;
}

.text-start {
  text-align: start !important;
}

.h-90 {
  height: 90%;
}

.h-73 {
  height: 73%;
}
.mx-7P {
  @include rtl {
    margin: 0 0 0 15px;
  }
  margin: 0 15px 0 0;
}

.form-select {
  font-size: 0.875rem;
  @include rtl {
    background-position: left 1rem;
    padding: 10px 0.75rem 10px 2.25rem !important;
  }
  background-size: 32px 12px;
  background-position: right 1rem;
  padding: 10px 2.25rem 10px 0.75rem !important;
  //background-color:$white
}

.form-control {
  background-color: $white;
}

.sortable {
  cursor: pointer;
}

.Asc::after {
  margin: 5px;
  //i need to arrow change the icon

  content: "\25B2";
}

.Desc::after {
  margin: 5px;
  content: "\25BC";
}

/* Forms validation */
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-color: #f8d7da !important;
  border-color: #dc3545 !important;
  background-image: none !important;
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  background-color: #f8d7da;
  border-color: #dc3545;
  background-image: none !important;
}

.was-validated .form-select:valid,
.form-select.is-valid {
  background-image: none !important;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none !important;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

div.invalid-input-wrapper + .invalid-feedback {
  display: unset !important;
}

input.is-invalid + .invalid-feedback + .valid-feedback {
  display: none;
}

input.is-invalid + .valid-feedback {
  display: none;
}

select.is-invalid + .invalid-feedback + .valid-feedback {
  display: none;
}

select.is-invalid + .valid-feedback {
  display: none;
}

.blank-layout {
  color: $darkGray;
  background: $white;
}

.auto-complete-input {
  position: relative !important;

  .suggestions {
    display: none;
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #ccc;
    z-index: 1000;
    background-color: #fff;
    color: $dark;
    border-radius: $border-radius;
  }

  .custom-loader {
    position: absolute;
    top: 12px;
    right: 18px;
  }

  .custom-loader[lang="ar"] {
    left: 18px;
    right: unset;
  }

  .suggestions .item {
    padding: 5px;
    font-weight: normal;
    display: block;
    min-block-size: 1.2em;
    white-space: nowrap;
  }
  .suggestions .item:hover {
    background-color: $primary;
    cursor: pointer;
    color: white;
  }
}

.custom-loader {
  width: 24px;
  height: 24px;
  border: 5px solid rgb(178, 188, 178);
  border-bottom-color: $primary;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.grab-cursor {
  cursor: grab;
}

.cursor-pointer {
  cursor: pointer;
}

.w-90 {
  width: 90%;
}

.mw-200p {
  min-width: 200px;
}

.file-name-container {
  background-color: var(--bs-light);
  padding: 5px;
  border-radius: var(--bs-border-radius-sm);
  border: var(--bs-border-width) solid var(--bs-border-color);
  color: var(--bs-dark);
}

.fs-12px {
  font-size: 0.7rem;
}

.file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 2px dashed $primary;
  border-radius: 5px;
  background-color: #ecf5f6;
}

.rtl .form-select {
  padding: 10px !important;
}

.ltr .form-select {
  padding: 10px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $white;
  text-decoration: none;
  background-color: $primary;
}

.header-circle {
  height: 14px;
  width: 14px;
  margin-inline: 10px;
  border-radius: 50%;
  border: 1px dotted $gray-500;
}

.border-light-300 {
  border: 1px solid $gray-300;
}
