.login-container {
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-form {
  max-width: 500px;
  width: 60vw;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.login-image {
  background-image: url("../../../image/login/LoginImage.jpeg");
  background-size: cover;
  background-position: center;
  height: 98%;
  border-radius: 20px;
  padding: 0 !important;
  @include phones-max {
    display: none;
  }
}
.login-image .overlay {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: space-between;
  color: white;
  border-radius: 20px;
}

.sing-up-container {
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;

  .step-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $primary;

    &.completed {
      background-color: $primary;
    }

    &.in-active {
      border: 1px solid rgba(119, 114, 114, 0.705);
    }
  }

  .sign-up-form {
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 600px;
    width: 60vw;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.rest-password-container {
  height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;

  .rest-password-form {
    max-width: 500px;
    width: 60vw;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rest-password-image {
    background-image: url("../../../image/login/LoginImage.jpeg");
    background-size: cover;
    background-position: center;
    height: 98%;
    border-radius: 20px;
    padding: 0 !important;
    @include phones-max {
      display: none;
    }
  }
  .rest-password-image .overlay {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: space-between;
    color: white;
    border-radius: 20px;
  }
}
