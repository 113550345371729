@import "../../../assets/style/theme/mixins/DirectionsMixins.Style.scss";

.popover-notification-container {
  max-width: 610px !important;
  width: 610px !important;

  @media screen and (max-width: 576px) {
    max-width: 80% !important;
    width: 80% !important;
  }

  .popover-notification-body {
    max-height: 400px !important;
    overflow-y: auto;
    width: 100%;
  }

  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--bs-primary);
  }
}

.header {
  .tasks-status-circle {
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: 2;
    @include rtl {
      left: auto;
      right: 2px;
    }
  }
}
