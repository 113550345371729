.notification-container {
  position: fixed;
  z-index: 9999;

  &.bottom-left {
    bottom: 2rem;
    left: 2rem;
  }

  &.bottom-right {
    bottom: 2rem;
    right: 2rem;
  }

  .min-w-200 {
    min-width: 200px;
  }
}
