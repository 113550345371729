.publications-details-container {
  padding-inline: 6rem;

  .book-image {
    background-size: cover;
    background-position: center;
    /* balck background above image with opasity 0.5*/
    background-color: rgba(0, 0, 0, 0.5);

    width: 240px;
  }

  .barcode {
    width: 120px;
  }

  .file-card {
    @media (max-width: 768px) {
      min-width: 100%;
    }

    @media (min-width: 768px) {
      min-width: 49%;
    }

    @media (min-width: 1400px) {
      min-width: 32%;
    }
  }

  .logo {
    width: 100%;
    height: 400px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 20px;
  }
}

.px-6rem {
  padding-inline: 6rem;
}

.h-100vh {
  height: 100svh;
}

.truncate-2lines {
  width: 300px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
