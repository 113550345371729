.action-stepper {
  display: flex;
  background-color: var(--bs-light);
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  overflow-x: auto;

  .step-container {
    min-width: 220px;
  }

  .step {
    display: flex;
    line-height: 1.4rem;

    .status-circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #f0f0f0;
      border: 1px dotted var(--bs-secondary);
      margin-top: 1rem;

      &.active {
        border-color: var(--bs-primary);
        background-color: hsl(from var(--bs-primary) h s l / 0.1);
      }

      &.completed {
        background-color: var(--bs-primary);
      }
    }
  }

  .rotate-icon {
    transform: rotate(180deg);
  }
}
