@media screen and (max-width: 576px) {
  .side-menu-wrapper {
    position: absolute !important;
    z-index: 1;
    left: -15px;
    height: calc(100vh - 96px + 15px);
    top: -15px;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.15);
    transition: right 0.3s, left 0.3s;
    will-change: right, left;

    @include rtl {
      right: -15px;
      left: unset;
    }

    &.collapsed {
      pointer-events: none;
      left: -315px;

      @include rtl {
        right: -315px;
        left: unset;
      }

      .backdrop {
        pointer-events: none;
        left: 100vw;
        opacity: 0;

        @include rtl {
          right: 100vw;
        }
      }
    }

    .backdrop {
      position: fixed;
      top: 80px;
      left: 290px;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
      transition: right 0.3s, left 0.3s, opacity 0.2s;
      will-change: right, left, opacity;
      opacity: 1;

      @include rtl {
        left: 0;
        right: 290px;
      }
    }

    .side-menu {
      border-radius: 0 !important;
    }
  }
}

.side-menu {
  // max-width: 410px;
  // min-width: 290px;
  // width: 15vw;
  width: 290px;
  background: $white;
  padding: 20px;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: width 0.3s;
  z-index: 1000;

  .menu-item-image {
    //width: 27px;
    //height: 30px;
    //margin-right: 10px;
  }
  .menu-item {
    justify-content: unset;
  }

  .side-menu-header {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .menu-item-text {
    flex: 1;
    text-align: start;
  }

  &.collapsed {
    max-width: 112px;
    min-width: 80px;
    width: 5vw;
    .menu-item-text {
      display: none;
    }
    .side-menu-section {
      p {
        display: none;
      }
    }
    .menu-button::after {
      @include rtl {
        content: url("../../../image/Icons/ArrowLeft.svg");
      }
      content: url("../../../image/Icons/ArrowRight.svg");
    }
    .menu-item {
      justify-content: center;
    }
    .side-dropdown-toggle {
      display: none;
    }
    .side-dropdown-toggle::after {
      display: none;
    }
    .side-dropdown-toggle.active::after {
      display: none;
    }
  }

  .side-menu-section {
    margin-bottom: 20px;
  }

  .side-menu-section ul {
    list-style: none;
    padding: 0;
  }

  .side-menu-section ul li {
    margin-bottom: 10px;
  }

  .side-menu-section ul li a {
    text-decoration: none;
    color: #333;
    display: flex;
    align-items: center;
  }

  .side-menu-section ul li a i {
    margin-right: 10px;
  }

  .submenu {
    padding-left: 40px;
    color: $gray;
    @include rtl {
      padding-right: 40px;
      padding-left: 0;
    }
    display: none;
  }

  .side-dropdown-toggle::after {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    width: 1em;
    height: 1em;
    margin-left: 0.8em;
    @include rtl {
      mask-image: url("../../../image/Icons/ArrowLeft.svg");
      -webkit-mask-image: url("../../../image/Icons/ArrowLeft.svg");
      margin-right: 0.8em;
      margin-left: 0;
    }
    mask-image: url("../../../image/Icons/ArrowRight.svg");
    -webkit-mask-image: url("../../../image/Icons/ArrowRight.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: currentColor;
    color: $gray;
    vertical-align: middle;
    content: " ";
    font-size: 16px;
    transition: transform 150ms;
  }

  .side-dropdown-toggle.active::after {
    transform: rotate(90deg);
    @include rtl {
      transform: rotate(-90deg);
    }
  }

  .p-13-16P {
    padding: 8px 16px;
  }

  .menu-button {
    cursor: pointer;
    background-color: #3992a4;
    top: 29px;
    @include rtl {
      right: unset;
      left: -15px;
    }
    right: -15px;
    left: unset;
    width: 13px;
    height: 55px;
    @include rtl {
      border-radius: 12px 0 0 12px;
    }
    border-radius: 0 12px 12px 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .menu-button::after {
    @include rtl {
      content: url("../../../image/Icons/ArrowRight.svg");
    }
    content: url("../../../image/Icons/ArrowLeft.svg");
  }
}
