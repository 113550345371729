.welcome-container {
  height: 100svh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/image/landingPage/background.jpg");
  background-size: cover;
  background-position: center;

  .welcome-text-container {
    max-width: 700px;
  }
}
