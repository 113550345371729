.flex-1 {
  flex: 1 !important;
}
.flex-2 {
  flex: 2 !important;
}
.flex-3 {
  flex: 3 !important;
}
.flex-4 {
  flex: 4 !important;
}
.flex-5 {
  flex: 5 !important;
}
.flex-6 {
  flex: 6 !important;
}
.flex-7 {
  flex: 7 !important;
}
.flex-8 {
  flex: 8 !important;
}
th {
  font-weight: 400;
  white-space: nowrap;
}
.divider {
  margin-block: 0.6rem;
  border-top: 1px solid var(--bs-border-color);
}

.p-10px {
  padding: 10px !important;
}

.rounded-50 {
  border-radius: 50% !important;
}

.fs-10px {
  font-size: 10px;
}

.fs-20px {
  font-size: 20px;
}

.px-4rem {
  padding-inline: 4rem;
}

.pb-4rem {
  padding-block: 4rem;
}

.p-10-56P {
  padding: 10px 56px;
}

.bg-danger-opacity-10 {
  background-color: rgba($danger, 0.1);
}

.bg-primary-opacity-10 {
  background-color: rgba($primary, 0.1);
}

.bg-success-opacity-10 {
  background-color: rgba($success, 0.1);
}

.bg-warning-opacity-10 {
  background-color: rgba($warning, 0.1);
}

.bg-info-opacity-10 {
  background-color: rgba($info, 0.1);
}

.bg-dark-opacity-10 {
  background-color: rgba($dark, 0.1);
}

.bg-light-opacity-10 {
  background-color: rgba($light, 0.1);
}

.bg-secondary-opacity-10 {
  background-color: rgba($secondary, 0.1);
}

.modal {
  .icon-parent-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: rgba($danger, 0.1);
    width: 84px;
    height: 84px;
    border-radius: 50%;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    // background-color: $danger;
  }

  .icon {
    width: 32px;
    height: 32px;
    color: white;
  }
}

.striped {
  > div:nth-child(odd) {
    background-color: $light;
  }
  > div:nth-child(even) {
    background-color: $white;
  }
}

.formio-component {
  label {
    font-weight: 700 !important;
  }
}

.custom-shadow {
  box-shadow: 0 0 8px 0 #00000014;
}

.hide-number-controls {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.text-overline {
  text-decoration: line-through !important;
}

.flex-grow-mobile {
  @media screen and (max-width: 576px) {
    flex-grow: 1;
  }
}

.flex-no-wrap-mobile {
  @media screen and (max-width: 576px) {
    flex-wrap: nowrap !important;
  }
}

.phone-number {
  @include rtl {
    direction: ltr;
    text-align: right;
  }
}

.p-0-important {
  padding: 0 !important;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

body.show-recaptcha {
  .grecaptcha-badge {
    visibility: visible !important;
  }
}

.k-animation-container {
  z-index: 9999 !important;
}
.formio-dialog.formio-dialog-theme-default .formio-dialog-close{
  @include rtl {
    left: 1px;
    right: unset;
  }
  right:1px
}