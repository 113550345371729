.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../../image/Header/bg-header.png");
  background-size: cover;
  background-color: $dark;
  padding: 10px 20px;
  height: 80px;
}
.left-section,
.right-section {
  display: flex;
  align-items: center;
}
.center-section {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.profile-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-decoration: none;
  min-width: 260px;
}
@media screen and (max-width: 576px) {
  .profile-link {
    min-width: unset;
  }
}
.profile-menu {
  min-width: 260px;
}
.profile-pic {
  width: 47px;
  height: 47px;
  border-radius: 15px;
}
.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-role {
  font-size: 0.7rem;
  color: $gray;
}
.notification-btn,
.message-btn {
  background: none;
  border: none;
  color: white;
  font-size: 1.4rem;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.program-link,
.current-programs-link,
.home-link {
  color: white;
  text-decoration: none;
  margin-left: 10px;
}

.home-icon {
  width: 38px;
  height: 60px;
}

@media screen and (max-width: 576px) {
  .home-icon {
    width: 28px;
    height: 45px;
  }
}

.fs-32px {
  font-size: 24px;
}

.left-section {
  .dropdown {
    .dropdown-item-padding {
      padding: 8px 16px;
    }

    .dropdown-menu {
      top: 8px !important;
      border-radius: 20px !important;

      .dropdown-item {
        height: auto;
      }

      .custom-accordion {
        .accordion-item {
          border: none;
          transition: all 0.3s ease;
        }

        .accordion-header {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: transparent;
          border-radius: 12px;
        }

        .accordion-body {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease, padding 0.3s ease;
          background-color: #ffffff;
          padding: 0;
          border-radius: 12px;
        }

        .accordion-item.active .accordion-body {
          max-height: 200px;
          padding: 0;
        }

        .icon-arrow-down {
          transform: rotate(360deg);
        }

        .accordion-item.active .accordion-header .icon-arrow-down {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }

      .toggle-btn {
        width: 3.5rem;
      }
    }
  }
}
