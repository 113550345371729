@mixin laptops-xxl-max {
  @media (max-width: 2560px) {
    @content;
  }
}
@mixin laptops-xl-max {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin laptops-l-max {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin laptops-max {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin tablets-max {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin phones-max {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin phones-s-max {
  @media (max-width: 576px) {
    @content;
  }
}
@mixin phones-xs-max {
  @media (max-width: 320px) {
    @content;
  }
}
