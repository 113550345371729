.file-input-container {
  .file-drop-zone {
    border: 1px dashed var(--bs-primary);
    background-color: hsl(from var(--bs-primary) h s l / 0.1);
    padding: 0.6rem;
    text-align: center;
    cursor: pointer !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: hsl(from var(--bs-primary) h s l / 0.3);
    }

    .file-input {
      opacity: 0; /* Hide the actual input */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
